* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  }

h1 {
  text-align: center;
}

body {
    font-family: Inter, -apple-system, BlinkMacSystemFont, 
                Segoe UI, Roboto, Helvetica, Arial, 
                sans-serif, Apple Color Emoji, 
                Segoe UI Emoji, Segoe UI Symbol;
}

/* topArea */
.topArea {
  width: 100%;
  position: sticky;
  top: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}

/* mainContent */
.mainContainer .mainContent {
  width: 100%;
  display: flex;
  justify-content: center;
}

.mainContainer .mainContent .container.width1200 {
  max-width: 1200px;
  width: 100%;
  display: flex;
  gap: 20px;
}

.mainContainer .mainContent .container .coinInfo {
  flex: 6.5;
}

.mainContainer .mainContent .container .chatApp {
  flex: 3.5;
  position: sticky;
  top: 40px; /* 상단에 고정 */
  height: 80vh;
  border-radius: 12px;
  border: 1px solid #ccc;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; /* Ensure .messageCotainer and .input-area are vertically aligned */
}


/* 먼저 1200px 이하 규칙 */
@media (max-width: 1200px) {
  .mainContainer .mainContent .container.width990 {
    max-width: 990px;
  }

  .mainContainer .mainContent .container .coinInfo {
    flex: 6;
  }

  .mainContainer .mainContent .container.chatApp {
    flex: 4;
  }
}

/* 그 다음 990px 이하 규칙 */
@media (max-width: 990px) {
  .mainContainer .mainContent .container.width770 {
    max-width: 770px;
  }

  .mainContainer .mainContent .container .coinInfo {
    flex: 7;
  }

  .mainContainer .mainContent .container .chatApp {
    flex: 3;
  }
}



@media (max-width: 770px) {
  /* .container에서 세로로 배치 */
  .mainContainer .mainContent .container.widthother {
    flex-direction: column; /* 세로 방향으로 배치 */
    gap: 10px; /* 세로 간격 설정 */
  }

  /* 각 섹션에 대한 크기 조정 */
  .mainContainer .mainContent .container .coinInfo, 
  .mainContainer .mainContent .container .chatApp {
    width: 100%; /* 너비를 100%로 설정하여 화면 전체 차지 */
  }

  .mainContainer .mainContent .container .chatApp {
    border-radius: 0;
    height: 440px;
  }
}