/* kimpTable */
.kimpTable {
    width: 100%;
    display: flex;
    justify-content: center;
}

.kimpTable .container {
    padding: 0 15px;
    border: 1px solid #ccc;
    border-radius: 12px;
    margin: 15px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 추가된 box-shadow */
}

.kimpTable .container .table {
    width: 100%;
    table-layout: fixed;
    font-size: 13px;
    border-spacing: 0; /* 셀 간의 여백 제거 */
}

.kimpTable .container .table .tableHeader,
.kimpTable .container .table .tableBody {
    width: 100%;
}

.kimpTable .container .table .tableHeader tr th .triangle {
    font-size: 10px;
}

/* tr 요소에 line-height 및 border-bottom 추가 */
.kimpTable .container .table tr {
    display: flex;
    line-height: 30px;
}

.kimpTable .container .table tr:hover {
    background-color: #f8f8f8;
}

/* th와 td에 대한 공통 스타일 */
.kimpTable .container .table th,
.kimpTable .container .table td {
    border-bottom: 1px solid #ccc;
    text-align: right;
}

/* 마지막 tr의 td border 제거 */
.kimpTable .container .table tr:last-child td {
    border: none;
}

/* 열 별 flex 비율 조정 */
.kimpTable .container .table th.simbolInfoTh,
.kimpTable .container .table td.simbolInfoTd {
    width: 80px;
}

.kimpTable .container .table th.bybitTh,
.kimpTable .container .table td.bybitTd {
    flex: 1; /* 바이비트 열 */
}

.kimpTable .container .table th.upbitTh,
.kimpTable .container .table td.upbitTd {
    flex: 1; /* 업비트 열 */
}

.kimpTable .container .table th.signedChangeRateTh,
.kimpTable .container .table td.signedChangeRateTd {
    flex: 0.7; /* 등락(%) 열 */
}

.kimpTable .container .table th.lowest52WeekPriceTh,
.kimpTable .container .table td.lowest52WeekPriceTd {
    flex: 1; /* 52주 최저 열 */
}

.kimpTable .container .table th.accTradePriceTh,
.kimpTable .container .table td.accTradePriceTd {
    flex: 1; /* 거래량 열 */
}

.kimpTable .container .table th.premiumTh,
.kimpTable .container .table td.premiumTd {
    flex: 2; /* 김치프리미엄 열 */
}

.kimpTable .container .table .tableBody .simbolInfoTd {
    display: flex;
}

.kimpTable .container .table .tableBody .simbolInfoTd .bookmark .bookmarkImg {
    width: 14px;
    height: 14px;
}

.kimpTable .container .table .tableBody .simbolInfoTd .left {
    display: flex;
    align-items: center; /* 수직 가운데 정렬 (div의 높이가 있을 때) */
}

.kimpTable .container .table .tableBody .simbolInfoTd .left .coinLogo {
    width: 16px;
    height: 16px;
    margin: 0 5px;
}

.kimpTable .container .table .tableBody .simbolInfoTd .right {
    margin-left: auto; /* 자동으로 왼쪽 마진을 채워서 오른쪽으로 밀기 */
}

.kimpTable .container .table .tableBody .rise {/*등락 --> 상승*/
    color: red;
}

.kimpTable .container .table .tableBody .fall {/*등락 --> 하락*/
    color: blue;
}

.kimpTable .container .table .tableBody .even {/*등락 --> 보합*/
    color: black;
}

.kimpTable .container .table .tableBody .kimp {/*김프*/
    color: blue;
}

.kimpTable .container .table .tableBody .reverse {/*역프*/
    color: red;
}

.kimpTable .container .table .tableBody .even {/*역프*/
    color: black;
}

/* 업비트 가격과 바이비트 가격 변동 시 배경색 변경 */
.fluctuation {
    width: 100%;
    height: 100%;
}

@keyframes colorChangeUp {
    0% { background-color: #C6E7FF; }
    25% { background-color: #d4edff; }
    50% { background-color: #e3f3ff; }
    100% { background-color: #f0f9ff; }
}

@keyframes colorChangeDown {
    0% { background-color: #F3CACA; }
    25% { background-color: #f1d0d0; }
    50% { background-color: #f0d9d9; }
    100% { background-color: #f0e0e0; }
}

.up {
    animation: colorChangeUp 1s ease forwards;
    animation-duration: 1s;
}

.down {
    animation: colorChangeDown 1s ease forwards;
    animation-duration: 1s;
}

/* 반응형 테이블 설계 - width 조정 중심 */
@media (max-width: 990px) {
    .kimpTable .container .table {
        font-size: 11px;
    }
    .kimpTable .container .table th.simbolInfoTh,
    .kimpTable .container .table td.simbolInfoTd {
        min-width: 60px;
    }
}

@media (max-width: 770px) {
    .kimpTable .container {
        padding: 0 3px;
        border-radius: 0;
    }
}

@media (max-width: 500px) {
    .kimpTable .container .table {
        font-size: 10px;
    }
    .kimpTable .container .table .tableHeader {
        font-size: 8px;
    }
    /* 모바일 환경에선 52주 최저 제거 */
    .lowest52WeekPriceTh {
        display: none;
    }

    .lowest52WeekPriceTd {
        display: none;
    }

    .kimpTable .container .table tr {
        line-height: 20px;
    }

    .kimpTable .container .table .tableHeader tr th .triangle {
        font-size: 8px;
    }

    /* 열 별 flex 비율 조정 */
    .kimpTable .container .table th.simbolInfoTh,
    .kimpTable .container .table td.simbolInfoTd {
        width: 65px;
    }

    .kimpTable .container .table th.premiumTh,
    .kimpTable .container .table td.premiumTd {
        flex: 2; /* 김치프리미엄 열 */
    }
}